.calendar-print-view {
    width: calc(var(--app-width) + 20px);
}

.calendar-print-view .calendar-container {
    margin-top: 0;
    padding: 10px;
}

.calendar-print-view .calendar-head {
    position: inherit;
    top: 30px;
}

.calendar-print-view .timeline-day-container {
    position: inherit;
}

.calendar-print-view .calendar-body-container {
    position: inherit;
    margin-top: -41px;
}