.calendar-container {
	margin-top: 340px;
}

.calendar-head {
	position: fixed;
	top: 264px;
	z-index: 1;
	background: white;
	width: var(--app-width);
}

.calendar-head-event {
	min-width: calc(var(--app-width) - var(--calendar-body-width));
}

.calendar-head-day {
	border: 1px solid black;
	height: 75px;
	border-right: 0;
	text-align: center;
}

.calendar-head :last-child.calendar-head-day {
	border-right: 1px solid black;
}

.calendar-body-container {
	width: var(--app-width);
	position: absolute;
	top: 315px;
	/* top: 300px; */
}

.calendar-body {
	min-height: 80px;
	height: auto;
	position: relative;
	/* background-color: var(--color-blue); */
	width: var(--calendar-body-width);
	margin-top: 41px;
	margin-bottom: 30px;
	margin-left: calc(var(--app-width) - var(--calendar-body-width));
	border-bottom: 1px solid black;
	padding-top: 5px;
	padding-bottom: 5px;
}

.calendar-body.planner {
	background-color: var(--color-orange);
}

.add-event-button {
	max-width: 150px;
}

.calendar-event {
	background-color: var(--color-blue);
	/*background-color: rgba(69, 146, 123, 0.5);*/
	height: auto;
	width: var(--app-width);
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: calc((var(--app-width) - var(--calendar-body-width)) * -1);
	padding-top: 3px;
	padding-bottom: 3px;
}

.calendar-event .event-name {
	display: inline-block;
	min-height: 40px;
	/* padding: 5px; */
	padding-left: 20px;
	/* padding-top: 10px; */
	width: calc(var(--app-width) - var(--calendar-body-width));
	box-sizing: border-box;
}

.calendar-event .calendar-event {
	margin-left: 50px;
	width: calc(100% - 50px);
}

.calendar-event .calendar-event .event-name {
	width: calc(var(--app-width) - 50px - var(--calendar-body-width));
}

.calendar-event .event-space {
	width: var(--calendar-body-width);
	height: 100%;
	/* margin-left: calc((var(--app-width) - var(--calendar-body-width)); */
	overflow: hidden;
}

.calendar-child-event .event-space {
	margin-left: calc(var(--app-width) - 50px - var(--calendar-body-width));
}

.calendar-timeslot-container {
	height: 35px;
	display: inline-block;
	position: relative;
}

.calendar-text {
	font-size: 0.95rem;
	user-select: none;
}

.calendar-timeslot {
	background-color: gold;
	width: 10px;
	height: 30px;
	/* display: inline-block; */
	/* cursor: move; */
	position: absolute;
	margin-top: 5px;
	-webkit-box-shadow: 5px 2px 10px 1px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 5px 2px 10px 1px rgba(0, 0, 0, 0.5);
	box-shadow: 5px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

.calendar-timeslot.event {
	background-color: #364f85;
}

.calendar-timeslot .resizer {
	/* background-color: rgba(200,0,0,0.5); */
	width: 10px;
	height: 100%;
	cursor: ew-resize;
	display: inline-block;
	position: absolute;
}

.calendar-timeslot .resizer.left {
	left: -6px;
}

.calendar-timeslot .resizer.right {
	right: -6px;
}

.no-events-message {
	height: auto;
	margin-left: calc((var(--app-width) - var(--calendar-body-width)) * -1);
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 90px;
}

.timeline-day-container {
	z-index: 3;
	height: 25px;
	margin-top: -25px;
	margin-left: 324px;
	position: fixed;
}

.timeline-time {
	background-color: white;
	position: absolute;
	display: inline-block;
	font-size: 11px;
	transform: translate(-50%, 0%);
}

.timeline-point {
	position: absolute;
	display: inline-block;
	font-size: 6px;
	margin-top: 15px;
}

.body-timeline {
	position: absolute;
	margin-top: -5px;
	width: var(--calendar-body-width);
	height: 100%;
	overflow: hidden;
}

.body-timeline .line {
	position: absolute;
	border-left: 1px solid grey;
	width: 1px;
	height: 100%;
}

.flex {
	display: flex;
}

.event-time-slot-text {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	color: white;
	/*font-weight: bold;*/
	font-size: 0.9rem;
	letter-spacing: -1px;
}

.event-time-slot-text.conflict {
	border: red 3px solid;
}

.text-val {
	mix-blend-mode: difference;
}

.resource-time-slot-text {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	color: black;
	/*font-weight: bold;*/
	font-size: 0.8rem;
	letter-spacing: -1px;
}

.resource-time-slot-text.conflict {
	border: red 3px solid;
}

.MuiOutlinedInput-adornedEnd {
	padding-right: 0px !important;
}

.MuiInputAdornment-positionEnd {
	margin-left: 0px !important;
}

.MuiInputAdornment-root .MuiIconButton-root {
	padding: 5px 5px 5px 1px !important;
}

input[type="text"]:disabled, textarea:disabled {
	opacity: 1;
	color: rgba(0, 0, 0, 0.38);
}